import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import dateFormat from "dateformat";
import { groupBy, isEmpty } from 'lodash';
import { useHistory, useLocation } from "react-router-dom";

import {
  BookingInfoContainer,
  Container,
  Tab,
  TabContainer,
  TabContent,
  TabsContainer,
} from './styles';

import Link from "../../../components/Link";
import Spinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import {
  H4,
  Button,
  Paragraph,
  H1, H3,
} from "../../../components";
import LoadImage from "../../../components/common/LoadImage";

import { fetchMyBookings } from './reducers';
import timeToString from "../../Clubs/Info/components/Calendar/helpers/timeToString";
import UserAvatar from "../../../components/UserAvatar";

const tabList = ['upcoming', 'history'];
const initialFilters = {
  page: 1,
  limit: 10,
  timeframe: 'upcoming',
};

const triggerScroll = (listRef) => {
  if (listRef?.current) {
    return listRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  return null;
};

const MyBookings = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const activeTab = params.get('tab');

  const { status, data: { results, totalPages } = [] } = useSelector((state) => state.dashboard.myBookings);
  const { accountInfo: { id, language } = {} } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const listRef = useRef(null);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (activeTab) {
      return handleTabs(activeTab);
    }

    return handleTabs('upcoming');
  }, [activeTab]);

  const handleTabs = (tab) => {
    if (status === 'idle' || filters.timeframe !== activeTab) {
      const newFilters = {
        ...filters,
        timeframe: tab,
        page: 1,
      };
      setFilters(newFilters);

      dispatch(fetchMyBookings({ id, filters: newFilters }));
    }
    history.push(`/${language}/my-account/bookings?tab=${tab}`);
  };

  const handlePagination = page => {
    const { selected } = page || {};
    if (filters.page - 1 !== selected) {
      const newFilters = {
        ...filters,
        page: selected + 1,
      };

      setFilters(newFilters);

      dispatch(fetchMyBookings({ id, filters: newFilters }));
      return window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    return null;
  };

  const groupedCompetitions = groupBy(results, 'courtInfo.sportType');
  const sportsByCompetitions = Object.keys(groupedCompetitions);

  return (
    <Container innerRef={listRef}>
      <H1>
        <Trans ns="clubs" i18nKey="myBookings">
          My Bookings
        </Trans>
      </H1>

      <TabsContainer>
        {tabList?.map((tab, key) => (
          <TabContainer
            onClick={() => handleTabs(tab)}
            active={tab === activeTab}
            key={key}
          >
            <Tab bold>
              <Trans ns="clubs" i18nKey={tab}>{tab}</Trans>
            </Tab>
          </TabContainer>
        ))}
      </TabsContainer>
      <TabContent>
        {status === 'loading' && (
          <Spinner height="350px" />
        )}

        {status === 'error' && (
          <Paragraph className="text-center p10">
            <Trans ns="clubs" i18nKey="couldNotFetchBookings">
              Failed to fetch bookings. Try again.
            </Trans>
          </Paragraph>
        )}

        {status === 'succeeded' && isEmpty(results) && (
          <div className="d-flex flex-column align-items-center mt70">
            <img src={LoadImage('clubs/court-type.svg')} alt="" width={82} height={50} />
            <H4 className="mt10 mb20">
              <Trans ns="clubs" i18nKey={`${activeTab}NoBookings`}>No Bookings</Trans>
            </H4>
            <Button wide>
              <Link className="slider-link" to="/clubs">
                <Trans ns="clubs" i18nKey="bookACourt">Book a Court</Trans>
              </Link>
            </Button>
          </div>
        )}

        {sportsByCompetitions.map((sport) => (
          <div className="mb30">
            <Paragraph large bold key={sport} className="text-capitalize">
              {sport}
            </Paragraph>
            {groupedCompetitions[sport].map(({ courtInfo, ...booking }, key) => (
              <BookingInfoContainer key={key}>
                <div className="date text-center">
                  <H3>{dateFormat(booking?.startDate, 'd')}</H3>
                  <Paragraph>{dateFormat(booking?.startDate, 'mmm')}</Paragraph>
                  <Paragraph className="mt10">{timeToString(booking?.startHour)}</Paragraph>
                </div>
                <div className="description">
                  <Link to={`/my-account/bookings/${booking._id}`}>
                    <Paragraph large bold>{booking?.clubInfo?.clubName}</Paragraph>
                    <span className="booking-id">{booking.bookingId}</span>
                  </Link>

                  <ul>
                    <li>
                      <strong>
                        <Trans ns="clubs" i18nKey="durationMin">{{ duration: booking?.duration }} min</Trans>
                      </strong>
                    </li>
                    <li><Trans ns="clubs" i18nKey="court">Court</Trans>: {courtInfo.name}</li>
                    <li>
                      <Trans ns="clubs" i18nKey={`surfaceType.${courtInfo?.surface}`}>{courtInfo?.surface}</Trans>
                    </li>
                    <li><Trans ns="clubs" i18nKey={`courtTypes.${courtInfo?.type}`}>{courtInfo?.type}</Trans></li>
                    <li><Trans ns="clubs" i18nKey={`courtSizes.${courtInfo?.size}`}>{courtInfo?.size}</Trans></li>
                  </ul>

                  {activeTab === 'history' && (
                    <Paragraph smaller bold className={`booking-status booking-status__${booking.status}`}>
                      <Trans ns="clubs" i18nKey={`${booking.status}Status`}>{booking.status}</Trans>
                    </Paragraph>
                  )}
                </div>
                {!isEmpty(booking.friendlyMatch) && (
                  <div className="friendly-match">
                    <Paragraph><Trans ns="clubs" i18nKey="friendlyMatch">Friendly Match</Trans></Paragraph>
                    <li className="match-players">
                      <ul>
                        <li><UserAvatar user={booking.friendlyMatch.player} size={38} /></li>
                        {!isEmpty(booking.friendlyMatch.playerPartner) && (
                          <li><UserAvatar user={booking.friendlyMatch.playerPartner} size={38} /></li>
                        )}
                        <li><UserAvatar user={booking.friendlyMatch.opponent} size={38} /></li>
                        {!isEmpty(booking.friendlyMatch.opponentPartner) && (
                          <li><UserAvatar user={booking.friendlyMatch.opponentPartner} size={38} /></li>
                        )}
                      </ul>
                    </li>
                  </div>
                )}
                <div className="go-to-booking">
                  <Link to={`/my-account/bookings/${booking._id}`}>
                    <img src={LoadImage('right-arrow.svg')} alt="" />
                  </Link>
                </div>
              </BookingInfoContainer>
            ))}
          </div>
        ))}

        <div onClick={() => triggerScroll(listRef)}>
          <Pagination
            pageCount={totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={filters.page - 1}
          />
        </div>
      </TabContent>
    </Container>
  );
};

export default MyBookings;
