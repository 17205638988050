import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';
import { debounce, isEmpty } from 'lodash';
import mixpanel from '../../../mixpanel';

import {
  JoinTitleStyle,
  JoinRow,
  FreePlayerPackage,
  Summary,
  PayMethod,
  DiscountModalStyle,
} from './styles';
import { StripeModal } from '../../MyAccount/Wallet/styles';
import { BuyModal } from '../../Plans/Components/Plan/styles';

// import Product1 from '../../../assets/images/products/product1.svg';
// import Product from './components/Product/Product'
import BasketItem from './components/BasketItem';
import Link from '../../../components/Link';
import LoadImage from '../../../components/common/LoadImage';
import Donate from './components/Donate/Donate';
import SearchBar from './components/Search/SearchBar';
import PenaltyTax from './components/penaltyTax';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Form/Button';
import Modal from '../../../components/Modal/Modal';
import { store as socketStore } from '../../../components/Socket/store';
import ContentLoader from '../../../components/ContentLoader';
import { H1, H2 } from '../../../components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import getCountryIsoCode from '../../../components/common/getCountryIsoCode';
import isRegisteredToCompetition from '../../../components/common/isRegisteredToCompetition';
import getRegistrationFee from '../../../components/common/getRegistrationFee';
import hasRestrictions from '../../../components/common/competitionHasRestrictions';
import BackButton from '../../../components/BackButton';

import { registerToEvent, updateAccount } from '../actions';

import { fetchEventDetails } from '../reducers';
import { updateAccountInfoState } from '../../../components/Layout/reducers/session';
import { clearMyEvents } from '../../MyAccount/Events/reducers';
import BillingDataSelector from '../../../components/BillingDataSelector';

const sizes = [
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
];

const penalty = false;

const JoinCompetition = () => {
  const { eventId, competitionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;
  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const { t, i18n } = useTranslation('events');
  const formRef = useRef();
  const { accountInfo, isLoggedIn } = useSelector(state => state.session);
  const { data: eventInfo, status } = useSelector(state => state.events?.info);

  const [size, setSize] = useState();
  const [partner, setPartner] = useState(null);
  const [discountModal, setDiscountModal] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [fees, setFees] = useState(false);
  const [priceInfo, setPriceInfo] = useState();
  const [bookingFee, setBookingFee] = useState(0);
  const [partnerFee, setPartnerFee] = useState(0);
  const [donation, setDonation] = useState();
  const [coupon, setCoupon] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [joinSubmitting, setJoinSubmitting] = useState(false);
  const [bookingFeeText, setBookingFeeText] = useState(false);
  const [partnerBookingFeeText, setPartnerBookingFeeText] = useState(false);
  const [billingAs, setBillingAs] = useState();

  const handleSubmit = () => {
    setJoinSubmitting(true);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const options = [
    { value: 'virtualWallet', label: t('join.payment.virtualWallet') },
    { value: 'creditCard', label: t('join.payment.creditCard') },
  ];

  useEffect(() => {
    if (status === 'idle' || (status === 'succeeded' && eventInfo?._id !== eventId)) {
      dispatch(fetchEventDetails(eventId));
    }
  }, [eventId, status, dispatch]);

  useEffect(() => {
    const listenForResponse = async ({ success, data, message }) => {
      dispatch(clearMyEvents({ type: 'joined' }));
      if (!success) {
        let errorMessage;
        setJoinSubmitting(false);

        switch (message) {
        case 'ERROR_BANNED_PARTNER':
          errorMessage = commonT(message, {
            partnerId: data?.partnerId,
            partnerName: data?.partnerName,
          });
          break;
        default:
          errorMessage = commonT(message);
        }

        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'events-response',
      );
      socket.on(
        'events-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'events-response',
        );
      };
    }
  }, [socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, data, message }) => {
      setJoinSubmitting(false);

      if (success) {
        if (data.paymentType === 'virtualWallet') {
          setConfirmPayment(!confirmPayment);
          dispatch(fetchEventDetails(eventId));
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          return history.push(
            `/${accountInfo?.language}/payment/confirmation/${eventId}-${competitionId}/${data.method}/virtual-wallet`,
          );
        }
        setRedirectModal(true);
        window.location.replace(data.url);
      } else {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'payments-response',
      );
      socket.on(
        'payments-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'payments-response',
        );
      };
    }
  }, [socketClientId]);

  // set total price
  useEffect(() => {
    let price = Number(bookingFee);
    if (donation?.amount > 0) {
      price += Number(donation?.amount);
    }
    if (coupon > 0) {
      price -= Number(coupon);
    }
    if (partnerFee > 0) {
      price += Number(partnerFee);
    }
    if (totalPrice !== price) {
      setTotalPrice(price);
      formRef.current.setFieldValue('amount', price, true);
    }
  }, [bookingFee, donation, coupon, partnerFee]);

  useEffect(() => {
    if (status === 'succeeded') {
      mixpanel.track('View Registration Checkout');
    }
  }, [status]);

  const logPayment = (method) => {
    mixpanel.track('Initiate Payment', {
      sp_event_registration_payment_method: method,
    });
  };

  const formSchema = Yup.object().shape({
    eventId: Yup.string().required(commonT('required')),
    competitionId: Yup.string().required(commonT('required')),
    paymentType: Yup.string().required(commonT('required')),
    amount: Yup.number().required(commonT('required')).when('paymentType', {
      is: 'virtualWallet',
      then: Yup.number().max(accountInfo?.walletBalance),
      otherwise: Yup.number(),
    }),
  });

  if (status === 'loading') {
    return (<ContentLoader title={false} />);
  }

  const [competitionInfo] = (eventInfo?.competitions || []).filter((v) => v._id === competitionId);
  if (status === 'failed' || (eventInfo?.competitions && !competitionInfo)) {
    return <Redirect to="/events" />;
  }

  const playerPackage = eventInfo?.playerPackage?.[i18n.language] ?? eventInfo?.playerPackage?.en ?? null;

  const partnerHandler = (user) => {
    if (!user) {
      setPartner(null);
      setPartnerFee(0);
    } else {
      setPartner(user);
      const fee = getRegistrationFee({
        user,
        competitionInfo,
        competitions: eventInfo?.competitions,
        playerCurrency: accountInfo?.currency,
      });

      setPartnerFee(fee);
      if (fee && Number(fee) < Number(priceInfo?.primary.replace(priceInfo?.currency, ''))
        && user?.membership?.plan === 'free') {
        setPartnerBookingFeeText(true);
      } else {
        setPartnerBookingFeeText(false);
      }
    }
  };

  const removeDonation = () => {
    setDonation(null);
  };

  if (competitionInfo) {
    const { currency } = getCountryIsoCode(eventInfo?.club?.location?.country);
    const [eventMainFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'RON' : accountInfo?.currency));
    const [eventSecondaryFees] = (competitionInfo?.fee || [])
      .filter((fee) => fee.currency === (!accountInfo ? 'BGN' : currency));

    const isRegistered = isRegisteredToCompetition(accountInfo?.userId, competitionInfo);
    if (isRegistered) {
      return <Redirect to={`/events/${eventId}/competitions/${competitionId}`} />;
    }

    if (!fees) {
      setFees({
        primary: eventMainFees,
        secondary: eventSecondaryFees,
      });
    }

    if (!priceInfo) {
      setPriceInfo({
        primary: `${eventMainFees?.free} ${eventMainFees?.currency}`,
        secondary: `${eventSecondaryFees?.free} ${eventSecondaryFees?.currency}`,
        primaryClubFee: `${eventMainFees?.clubEntryFee} ${eventMainFees?.currency}`,
        secondaryClubFee: `${eventSecondaryFees?.clubEntryFee} ${eventSecondaryFees?.currency}`,
        membershipDiscountedPrice: getRegistrationFee({
          user: accountInfo,
          competitionInfo,
          competitions: eventInfo?.competitions,
        }),
        currency: eventMainFees?.currency,
      });
    }

    if (priceInfo && !bookingFee && Number(priceInfo?.membershipDiscountedPrice) > 0) {
      if (accountInfo?.membership?.plan === 'free'
        && Number(priceInfo?.membershipDiscountedPrice) < Number(priceInfo?.primary.replace(priceInfo?.currency, ''))) {
        setBookingFeeText(true);
      }

      setBookingFee(priceInfo?.membershipDiscountedPrice);
    }

    // if registration fee is 0, remove credit card option
    if (Number(totalPrice) === 0) {
      delete options[1]; // remove credit card option
    }

    // eslint-disable-next-line no-constant-condition
    if (false) {
      setCoupon(0);
    }
  }

  if (isLoggedIn) {
    if (accountInfo?.tShirtSize) {
      const [tShirtSize] = sizes.map((value, index) => {
        if (value.value === accountInfo?.tShirtSize) {
          return index;
        }

        return false;
      }).filter(index => !!index);
      if (!size && tShirtSize) {
        setSize(tShirtSize);
      }
    }
  }

  const handleTShirtSize = async (value) => {
    setSize(value);
    const payload = { tShirtSize: value.value };
    dispatch(updateAccountInfoState(payload));
    await updateAccount({
      id: accountInfo?.id, payload, t: commonT, addToast,
    });
  };

  const onSubmitHandler = async ({ method, paymentType, ...values }) => {
    setJoinSubmitting(true);

    const payload = {
      productId: competitionId,
      eventId,
      competitionId,
      method,
      paymentType,
      products: [],
      billingAs,
    };

    // player event registration
    payload.products.push({
      id: competitionId,
      name: commonT('eventRegistration', { eventName: eventInfo?.name }),
      price: Number(bookingFee).toFixed(2),
      quantity: 1,
      type: method,
    });

    // add player package (free)
    if (!isEmpty(playerPackage) && eventInfo?.playerPackage?.tShirt) {
      payload.products.push({
        id: `t-shirt-${accountInfo?.gender}-${accountInfo?.tShirtSize}`,
        name: t('tShirtProduct', { size: accountInfo?.tShirtSize, gender: commonT(accountInfo?.gender) }),
        price: 0,
        quantity: 1,
        type: 't-shirt',
      });
    }

    // donation
    if (donation) {
      payload.products.push({
        id: donation?.id,
        name: t('sprintenDonation', { amount: donation?.amount, currency: donation?.currency }),
        price: Number(donation?.amount).toFixed(2),
        quantity: 1,
        type: 'donation',
      });
    }

    // partner
    if (partner) {
      payload.products.push({
        id: competitionId,
        name: commonT('partnerEventRegistration', { eventName: eventInfo?.name }),
        price: Number(partnerFee).toFixed(2),
        quantity: 1,
        type: `${method}Partner`,
      });
    }

    await registerToEvent({
      eventId: values.eventId,
      competitionId: values.competitionId,
      payload,
      addToast,
      socketClientId,
      partner,
      t: commonT,
    });
  };

  const doublesRestrictionLevel = competitionInfo?.level;
  const doublesMaxPlayerLevel = competitionInfo?.level - 0.5;

  const restrictions = hasRestrictions(competitionInfo);

  const needsPartner = competitionInfo?.gameType === 'doubles';
  const isBeforeClosingRegistrations = new Date() < new Date(eventInfo?.phases?.playing?.endDate);
  const isPremium = accountInfo?.membership.plan === 'premium';
  const hasDiscount = competitionInfo?.premiumDiscount > 0;

  return (
    <JoinTitleStyle>
      <Helmet>
        <title>{t('joinCompetition.seoTitle')}</title>
      </Helmet>
      <div className="wrapp_join">
        <div className="joinTitle">
          <BackButton className="mr10" {...history} to={`/events/${eventId}/competitions/${competitionId}`} bold />
          <H1><Trans ns="events" i18nKey="joinAndPay">Join and Pay</Trans></H1>
        </div>

        <JoinRow>
          <div className="wrappShopSummary">
            <div className="left">
              {needsPartner && (
                <SearchBar partner={partner} userHandler={partnerHandler} sportType={eventInfo?.sportType} />
              )}
              <H2>{eventInfo?.name}</H2>

              <ul className="event-info">
                <li>
                  <Trans ns="events" i18nKey="level">Level</Trans>
                  {' '}
                  {competitionInfo?.level}
                </li>
                <li>{eventInfo.category}</li>
                <li><Trans ns="events" i18nKey={competitionInfo?.gameType} /></li>
                <li>
                  {competitionInfo?.mlMaxNumber}
                  {' '}
                  <Trans ns="common" i18nKey="players">Players</Trans>
                  {' '}
                  (
                  {competitionInfo?.numberOfGroups > 0 && `${competitionInfo?.numberOfGroups} `}
                  <Trans ns="events" i18nKey={competitionInfo?.drawModel}>Eliminatory</Trans>
                  {competitionInfo?.phases?.length > 0 && (
                    <>
                      {' '}
                      +
                      <Trans i18nKey={competitionInfo?.phases[0]?.drawModel} ns="events">
                        {` + ${competitionInfo?.phases[0]?.drawModel}`}
                      </Trans>
                    </>
                  )}
                  )
                </li>
              </ul>
              {restrictions.includes(true)
                ? (
                  <ul className="event-restrictions">
                    <li>
                      <strong>
                        <Trans ns="events" i18nKey="restrictions">Restrictions</Trans>
                      </strong>
                      :
                    </li>
                    {restrictions[2] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="common" i18nKey={`only${competitionInfo?.restrictions?.gender}`}>Gender</Trans>
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[3] && !restrictions[4] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="common" i18nKey="minage">Min. Age</Trans>
                          {' '}
                          {`${competitionInfo?.restrictions?.minAge}+`}
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[4] && !restrictions[3] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="common" i18nKey="maxage">Max. Age</Trans>
                          {' '}
                          {competitionInfo?.restrictions?.maxAge}
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[4] && restrictions[3] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="common" i18nKey="age">Age</Trans>
                          {' '}
                          {`${competitionInfo?.restrictions?.minAge ?? 0}-${competitionInfo?.restrictions?.maxAge}`}
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[0] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="events" i18nKey={`min${competitionInfo?.restrictions?.accountActivity}event`} />
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[1] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="events" i18nKey={competitionInfo?.restrictions?.accountType} />
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[5] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="common" i18nKey="rank">Rank</Trans>
                          {' '}
                          {`${competitionInfo?.restrictions?.rank ?? 0}+`}
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[6] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="events" i18nKey="restrictionsDoubleLevel">
                            Maximum Doubles player level
                            {{ level: doublesRestrictionLevel }}
                          </Trans>
                        </Paragraph>
                      </li>
                    ) : ''}
                    {restrictions[7] ? (
                      <li>
                        <Paragraph small>
                          <Trans ns="events" i18nKey="restrictionsTeamLevel">
                            Team max.level -
                            {{ level: doublesMaxPlayerLevel }}
                          </Trans>
                        </Paragraph>
                      </li>
                    ) : ''}
                  </ul>
                ) : ''}

              {/* Match  Info */}
              <div className="matchInfo">
                <div className="matchStart">
                  <Paragraph bold><Trans ns="events" i18nKey="join.matchStart">Match Start:</Trans></Paragraph>
                  <Paragraph>{dateFormat(competitionInfo?.startDate, 'd mmm (dddd), HH:MM')}</Paragraph>
                </div>

                {!isEmpty(eventInfo?.club)
                  && (
                    <div className="matchLocation">
                      <Paragraph bold>
                        <Trans ns="common" i18nKey="location">
                          Location
                        </Trans>
                        :
                      </Paragraph>
                      <Paragraph>
                        {eventInfo?.club?.clubName}
                        {eventInfo?.club?.location?.name && ` - ${eventInfo?.club?.location?.name}`}
                      </Paragraph>
                    </div>
                  )}
              </div>

              {!isEmpty(playerPackage)
                && (
                  <FreePlayerPackage>
                    <div className="freePlayerPackage">
                      <Paragraph className="PlayerPackageTitle" medium bold>{playerPackage?.title}</Paragraph>
                      <Paragraph>{playerPackage?.description}</Paragraph>

                      {(accountInfo && eventInfo?.playerPackage?.tShirt)
                        && (
                          <div className="sizeWrapp">
                            <Paragraph bold>
                              <Trans ns="events" i18nKey="yourTShirtSize">Your T-Shirt Size</Trans>
                            </Paragraph>
                            <Select
                              className="sizeSelect"
                              placeholder="size"
                              value={sizes[size]}
                              onChange={handleTShirtSize}
                              options={sizes}
                            />
                          </div>
                        )}
                    </div>
                  </FreePlayerPackage>
                )}

              {accountInfo?.currency === 'RON' && (
                <Donate donation={donation} setDonation={setDonation} currency={priceInfo?.currency} />
              )}
            </div>
            <div className="right">
              <Summary>
                <div>
                  <H2><Trans ns="events" i18nKey="summary">Summary</Trans></H2>

                  <div className="d-flex justify-content-between">
                    <Paragraph><Trans ns="events" i18nKey="bookingFee">Booking fee</Trans></Paragraph>
                    <Paragraph>
                      {priceInfo?.membershipDiscountedPrice || 0} {priceInfo?.currency}
                    </Paragraph>
                  </div>

                  {bookingFeeText && (
                    <div className="d-flex justify-content-between">
                      <Paragraph>
                        <Trans ns="events" i18nKey="secondGameDiscountIncluded">
                          (incl. - {{ amount: competitionInfo?.secondGameDiscount ?? '-' }}
                          {{ currency: competitionInfo?.currency ?? '' }} Discount for the 2nd Competition)
                        </Trans>
                      </Paragraph>
                    </div>
                  )}

                  {isPremium && hasDiscount && (
                    <div className="d-flex justify-content-between">
                      <Paragraph>
                        <Trans ns="events" i18nKey="premiumDiscountIncluded">
                          (incl. - {{ amount: competitionInfo?.premiumDiscount ?? '-' }}
                          {{ currency: competitionInfo?.currency ?? '' }} Premium Discount)
                        </Trans>
                      </Paragraph>
                    </div>
                  )}

                  {partner
                    && (
                      <div className="d-flex justify-content-between">
                        <Paragraph>
                          <Trans ns="events" i18nKey="partnerBookingFee">Partner booking fee</Trans>
                        </Paragraph>
                        <Paragraph>
                          {partnerFee} {priceInfo?.currency}
                        </Paragraph>
                      </div>
                    )}

                  {partnerBookingFeeText && (
                    <div className="d-flex justify-content-between">
                      <Paragraph>
                        <Trans ns="events" i18nKey="secondGameDiscountIncluded">
                          (incl. - {{ amount: competitionInfo?.secondGameDiscount ?? '-' }}
                          {{ currency: competitionInfo?.currency ?? '' }} Discount for the 2nd Competition)
                        </Trans>
                      </Paragraph>
                    </div>
                  )}

                  {partner && partner?.membership.plan === 'premium' && competitionInfo?.premiumDiscount > 0 && (
                    <div className="d-flex justify-content-between">
                      <Paragraph>
                        <Trans ns="events" i18nKey="premiumDiscountIncluded">
                          (incl. - {{ amount: competitionInfo?.premiumDiscount ?? '-' }}
                          {{ currency: competitionInfo?.currency ?? '' }} Premium Discount)
                        </Trans>
                      </Paragraph>
                    </div>
                  )}

                  {/*
                  {accountInfo?.membership?.plan === 'free' && (
                    <div className="premiumSale">
                      <Paragraph small>
                        <Trans ns="events" i18nKey="getPremiumDiscount">
                          Get to a Premium Account and pay only
                          <strong>{{ amount: fees?.primary?.premium }} {{ currency: priceInfo?.currency }}</strong>
                        </Trans>
                      </Paragraph>
                      <Paragraph smaller>
                        <Trans ns="events" i18nKey="learnMore">Learn More</Trans>
                      </Paragraph>
                    </div>
                  )} */}
                  {!isEmpty(donation)
                    && (
                      <div>
                        <Paragraph>
                          <Trans ns="events" i18nKey="sprintenDonation">SPRINTEN Donation</Trans>
                        </Paragraph>
                        <Paragraph>
                          {donation?.amount}
                          {' '}
                          {donation?.currency}
                          {' '}
                          <span onClick={removeDonation}>X</span>
                        </Paragraph>
                      </div>
                    )}
                  {!isEmpty(coupon)
                    && (
                      <div>
                        <Paragraph>
                          <Trans ns="events" i18nKey="couponDiscount">
                            Coupon
                            <span>Remove Coupon</span>
                          </Trans>
                        </Paragraph>
                        <Paragraph>
                          -
                          {coupon?.amount}
                          {' '}
                          {coupon?.currency}
                        </Paragraph>
                      </div>
                    )}
                  <hr />

                  <div className="shoppingBasket">
                    {!isEmpty(playerPackage && false) && (
                      <>
                        <Paragraph small bold>
                          <Trans ns="events" i18nKey="shoppingBasket">Shopping Basket</Trans>
                        </Paragraph>

                        {/* <BasketItem qty={4} name="Mingea oficiala Sportya (set 4 buc)" price={40} /> */}
                        {/* <BasketItem qty={1} name="Set mansete Wilson (2 buc.)" price={22} /> */}
                        {/* <BasketItem qty={4} name="Mingea oficiala Sportya (set 4 buc)" price={40} /> */}

                        {eventInfo?.playerPackage?.tShirt
                          && (
                            <BasketItem
                              qty={1}
                              name={t('tShirtProduct', {
                                size: accountInfo?.tShirtSize,
                                gender: commonT(accountInfo?.gender),
                              })}
                              price={0}
                              currency={priceInfo?.currency}
                              change={false}
                            />
                          )}

                        <hr />
                      </>
                    )}

                    {penalty && <PenaltyTax />}

                    <div className="totalWrapp">
                      <div>
                        <Paragraph large bold>
                          <Trans ns="events" i18nKey="totalToBePaid">Total to be paid</Trans>
                        </Paragraph>
                        <Paragraph small>
                          <Trans ns="events" i18nKey="inclVAT">incl. TVA</Trans>
                        </Paragraph>
                      </div>

                      <div>
                        <Paragraph medium bold>
                          {totalPrice}
                          {' '}
                          {priceInfo?.currency}
                        </Paragraph>
                        {/* <Paragraph smaller className="addCoupon"> */}
                        {/*  <Trans ns="events" i18nKey="addCoupon">Add Coupon</Trans> */}
                        {/* </Paragraph> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="participationFeeWrapp">
                  <div>
                    <Paragraph small bold>
                      <Trans ns="events" i18nKey="participationFee">Participation fee</Trans>
                    </Paragraph>
                    {!Number.isNaN(fees?.primary?.clubEntryFee)
                      ? <Paragraph small bold>{priceInfo?.secondaryClubFee}</Paragraph>
                      : (
                        <Paragraph small>
                          <Trans ns="events" i18nKey="priceNotAvailable">Price Unavailable</Trans>
                        </Paragraph>
                      )}
                  </div>
                  <Paragraph small>
                    <Trans ns="events" i18nKey="participationFeeInfo">
                      Is paid directly to organizer when join the tournament. Organizer Club:
                      <strong>{{ clubName: eventInfo?.club?.clubName }}</strong>
                    </Trans>
                  </Paragraph>
                  <img src={LoadImage('plus-icon.svg')} alt="plusIcon" />
                </div>
              </Summary>

              <PayMethod>
                <div className="payMethod">
                  <H2><Trans ns="events" i18nKey="join.payment.title">Payment Method</Trans></H2>
                  <div className="selectedPlaceholder">
                    <BillingDataSelector billingAs={billingAs} setBillingAs={setBillingAs} />

                    <Formik
                      // enableReinitialize={true}
                      innerRef={formRef}
                      initialValues={{
                        method: 'eventRegistration',
                        paymentType: '',
                        amount: totalPrice,
                        eventId,
                        competitionId,
                      }}
                      validationSchema={formSchema}
                      onSubmit={debounce(onSubmitHandler, 150)}
                    >
                      {({
                        values, setFieldValue, isValid, dirty,
                      }) => {
                        if (totalPrice === 0 && values.paymentType !== 'virtualWallet') {
                          setFieldValue('paymentType', 'virtualWallet');
                        }
                        return (
                          <Form>
                            <Select
                              className="select-method"
                              name="paymentType"
                              placeholder={t('join.payment.selectMethod')}
                              options={options}
                              onChange={({ value }) => setFieldValue('paymentType', value)}
                              isSearchable={false}
                              {...(totalPrice === 0) && {
                                defaultValue: { value: 'virtualWallet', label: t('join.payment.virtualWallet') },
                              }}
                            />

                            {values.paymentType === 'virtualWallet' ? (
                              <>
                                <div className="availableBalance">
                                  <Paragraph small bold>
                                    <Trans ns="events" i18nKey="join.payment.availableBalance">Available Balance</Trans>
                                  </Paragraph>
                                  <Paragraph
                                    smaller
                                    bold
                                    className={values.amount > accountInfo?.walletBalance && 'insufficientFunds'}
                                  >
                                    {values.amount > accountInfo?.walletBalance && (
                                      <span>
                                        <Trans ns="events" i18nKey="join.payment.insufficientFunds">
                                          Insufficient Funds
                                        </Trans>
                                      </span>
                                    )}
                                    {accountInfo?.walletBalance < 0 && (
                                      <span>
                                        <Trans ns="events" i18nKey="join.payment.negativeFunds">Negative funds</Trans>
                                      </span>
                                    )}
                                    {accountInfo?.walletBalance.toFixed(2)}
                                    {' '}
                                    {accountInfo?.currency}
                                  </Paragraph>
                                </div>

                                {/*
                              {values.paymentType === "virtualWallet" && accountInfo?.walletBalance < values.amount && (
                                <div className="topUp">
                                  <Paragraph smaller bold>
                                    Top up and pay in one step! Please choose an amount
                                  </Paragraph>
                                  <div>{topUpButtons.map((topUp, idx) => (
                                    <button
                                    key={idx}
                                     className={activeTopUpButton.value === topUp.value && activeTopUpButton.select
                                     ? "selected" : ""}
                                      onClick={() => selectTopUpHandler(idx)}
                                    >
                                    {activeTopUpButton.value === topUp.value && activeTopUpButton.select
                                    ? <img src={LoadImage("checkmark.svg")} alt="checkIcon" />
                                    : ""} {topUp.text}</button>)}</div>
                                </div>
                              )}
                              */}

                                <div className="payBtnWrapp">
                                  <Button
                                    type="button"
                                    large
                                    fullwidth
                                    disabled={(
                                      !(isValid && dirty)
                                      || !values.paymentType
                                      || joinSubmitting
                                      || (competitionInfo?.gameType === 'doubles' && !partner)
                                      || !isBeforeClosingRegistrations
                                    )}
                                    onClick={() => {
                                      setConfirmPayment(!confirmPayment);
                                      logPayment('wallet');
                                    }}
                                  >
                                    <Trans ns="events" i18nKey="join.payment.confirmPay">Confirm & Pay</Trans>
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <div className="payBtnWrapp">
                                <Button
                                  type="submit"
                                  large
                                  fullwidth
                                  disabled={(
                                    !(isValid && dirty)
                                    || !values.paymentType
                                    || joinSubmitting
                                    || (competitionInfo?.gameType === 'doubles' && !partner)
                                    || !isBeforeClosingRegistrations
                                  )}
                                  onClick={() => logPayment('credit-card')}
                                >
                                  {joinSubmitting
                                    ? <LoadingSpinner formBtn />
                                    : <Trans ns="events" i18nKey="join.payment.confirmPay">Confirm & Pay</Trans>}
                                </Button>
                                {values.paymentType === 'creditCard' && (
                                  <div className="stripe-logo text-center mt10">
                                    <img src={LoadImage('powered-by-stripe.svg')} alt="Stripe" />
                                  </div>
                                )}
                              </div>
                            )}

                            {redirectModal && (
                              <Modal
                                hideClose
                                type2
                                noborder
                                nobackground
                                hide={() => setRedirectModal(!redirectModal)}
                                isShowing
                              >
                                <StripeModal>
                                  <img src={LoadImage('stripe-logo.svg')} alt="Stripe" />
                                  <Paragraph>
                                    <Trans ns="events" i18nKey="stripeRedirect">Redirecting to Stripe...</Trans>
                                  </Paragraph>
                                  <LoadingSpinner className="loader" />
                                </StripeModal>
                              </Modal>
                            )}

                            {confirmPayment && (
                              <Modal
                                smallRadius
                                type2
                                noborder
                                small
                                nobackground
                                hide={() => setConfirmPayment(!confirmPayment)}
                                isShowing
                              >
                                <BuyModal>
                                  <div className="modalHeader">
                                    <H2><Trans ns="events" i18nKey="confirmPayment">Confirm your payment</Trans></H2>
                                    <div className="confirmPaymentInfo">
                                      <Paragraph className="mb40 text-center">
                                        <Trans ns="events" i18nKey="pleaseConfirmPaymentForEvent">
                                          Please confirm your final payment with your virtual wallet for this selected
                                          event.
                                        </Trans>
                                      </Paragraph>
                                      <div className="info">
                                        <Paragraph small>
                                          <Trans ns="events" i18nKey="join.payment.availableBalance">
                                            Available Balance
                                          </Trans>
                                        </Paragraph>
                                        <Paragraph small>
                                          {accountInfo?.walletBalance.toFixed(2)}
                                          {' '}
                                          {accountInfo?.currency}
                                        </Paragraph>
                                      </div>
                                      <hr />
                                      <div className="info">
                                        <Paragraph small bold>
                                          <Trans ns="events" i18nKey="amountToBePaid">Amount to be Paid</Trans>
                                        </Paragraph>
                                        <Paragraph small>
                                          <strong>
                                            {values.amount}
                                            {' '}
                                            {accountInfo?.currency}
                                          </strong>
                                        </Paragraph>
                                      </div>
                                      <hr />
                                      <div className="info">
                                        <Paragraph small>
                                          <Trans ns="events" i18nKey="remainingBalance">Remaining Balance</Trans>
                                        </Paragraph>
                                        <Paragraph small>
                                          {accountInfo?.walletBalance - values.amount}
                                          {' '}
                                          {accountInfo?.currency}
                                        </Paragraph>
                                      </div>

                                      <div className="buttons mt40">
                                        <Button
                                          type="button"
                                          large
                                          fullwidth
                                          outline
                                          onClick={() => setConfirmPayment(!confirmPayment)}
                                        >
                                          <Trans ns="events" i18nKey="cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                          type="submit"
                                          disabled={!(isValid && dirty) || joinSubmitting}
                                          max
                                          large
                                          fullwidth
                                          onClick={debounce(handleSubmit, 150)}
                                        >
                                          {joinSubmitting
                                            ? <LoadingSpinner formBtn />
                                            : <Trans ns="events" i18nKey="confirmPay">Confirm & Pay</Trans>}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </BuyModal>
                              </Modal>
                            )}
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>

                  <div className="rules">
                    <Paragraph smaller>
                      <Trans ns="events" i18nKey="payRule">
                        Prin selectarea butonului de alături confirm că am citit, am înțeles și sunt de acord cu
                        <Link to="/rules-regulations" target="_blank">Sportya - Reguli și Regulamente</Link>.
                        Confirm că sunt apt fizic și îmi asum întreaga răspundere pentru participare.
                        Sunt de acord ca numărul meu de telefon să fie distribuit organizatorului acestui eveniment
                        și/sau, în cazul Ligilor Locale, jucătorilor grupei din care fac parte. De asemenea,
                        confirm că am citit, am înțeles și sunt de acord cu
                        <Link to="/return-policy" target="_blank">Politica de Retur</Link>.
                        Sunt de acord ca prestația serviciilor să
                        înceapă în timpul perioadei de 14 zile de retragere din contract. Am luat la cunoștință faptul
                        că dreptul meu de retragere se va pierde după executarea completă a serviciilor achiziționate.
                      </Trans>
                    </Paragraph>
                  </div>
                </div>

                <div onClick={() => setDiscountModal(true)} className="discount cursor-pointer">
                  <img src={LoadImage('discount-icon.svg')} alt="discount" />
                  <Paragraph bold>
                    <Trans ns="events" i18nKey="learnMoreDiscount">
                      Get a Discount
                      {' '}
                      <span>Learn More</span>
                    </Trans>
                  </Paragraph>
                </div>

                {discountModal && (
                  <Modal smallRadius wide type2 noborder nobackground hide={() => setDiscountModal(false)} isShowing>
                    <DiscountModalStyle>
                      <H2>
                        <Trans ns="events" i18nKey="discountModalTitle">Discount inscriere la 2 probe</Trans>
                      </H2>
                      <div className="discountContentWrapp">
                        <Paragraph medium>
                          <Trans ns="events" i18nKey="discountModalDescription">
                            Jucatorii posesori de conturi <span>GRATUITE</span> beneficiaza de un discount in valoare de
                            <span> 15 {priceInfo?.currency}</span> la inscrierea in 2 probe din cadrul unui turneu
                            Silver sau Special, respectiv <span>25 {priceInfo?.currency}</span> la inscrierea in 2
                            probe din cadrul unui turneu Gold. Discount-ul se aplica automat in momentul inscrierii,
                            conditia fiind ca inregistrarea in prima proba, indiferent daca este de tip simplu, dublu
                            sau pe echipe, sa fie validata in sistem (plata realizata) inainte de realizarea rezervarii
                            in cea de-a 2-a proba.
                          </Trans>
                        </Paragraph>
                      </div>
                    </DiscountModalStyle>
                  </Modal>
                )}
              </PayMethod>
            </div>
          </div>
        </JoinRow>
      </div>
    </JoinTitleStyle>
  );
};

export default JoinCompetition;
