/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccount } from './actions';

import Button from '../../../components/Form/Button';
import { store as socketStore } from '../../../components/Socket/store';
import { SetFavoriteSportWrapper } from './styles';
import { getAccountInfo } from '../../../components/Layout/reducers/session';
import Logout from '../../../components/Logout';
import { H2 } from "../../../components";
import LoadImage from "../../../components/common/LoadImage";
import Radio from "../../../components/Form/RadioCheck";

const setFavoriteSport = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('accounts');
  const { t: commonT } = useTranslation('common');
  const { accountInfo, profileCompletion, masterSport } = useSelector(state => state.session);
  const { activeSports } = useSelector(state => state.settings?.data);

  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const formSchema = Yup.object().shape({
    favoriteSport: Yup.string().required(commonT('required')),
  });

  const getNextStep = () => {
    let list = [];
    profileCompletion?.forEach(step => {
      if (!step.completed) {
        list = [...list, step.path];
      }
    });

    const index = list.findIndex(path => path === '/my-account/favorite-sport') + 1;
    return list.length && list[index] ? list[index] : '/dashboard?congrats';
  };

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      await addToast(commonT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
      });

      if (success) {
        dispatch(getAccountInfo({ masterSport }));
        await new Promise(r => setTimeout(r, 500));
        history.push(getNextStep());
      }
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);
      return () => socket.removeAllListeners('accounts-response');
    }
  }, [socketClientId]);

  return (
    <SetFavoriteSportWrapper>
      <Logout />

      <div className="container">
        <H2 className="mb10 text-center">
          <Trans ns="accounts" i18nKey="completeProfile.chooseFavoriteSport">
            Choose Your Favourite Sport
          </Trans>
        </H2>
        <p className="mb45 text-center">
          <Trans
            ns="accounts"
            i18nKey="completeProfile.chooseFavoriteSportSubtitle"
          >
            This should be the main sport you play. Other sports can be added
            later from your account settings.
          </Trans>
        </p>

        <Formik
          initialValues={{
            favoriteSport: '',
          }}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await updateAccount({
              id: accountInfo.id,
              payload: values,
              addToast,
              setSubmitting,
              socketClientId,
              dispatch,
              history,
              t,
            });
          }}
        >
          {({
            values,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <Form className="w100">
              <div className="radio-wrapper" role="group" aria-labelledby="favoriteSport">
                {activeSports.map((sport, key) => (
                  <div className={`field-item ${values.favoriteSport === sport ? 'checked' : ''}`} key={key}>
                    <Radio
                      key={key}
                      name="favoriteSport"
                      label={(
                        <>
                          <span>
                            <img src={LoadImage(`sports/${sport}.svg`)} alt={sport} width={26} />
                            <Trans ns="common" i18nKey={`sports.${sport}`} />
                          </span>
                        </>
                      )}
                      value={sport}
                      checkmark
                      icon={LoadImage('checkmark-arrow.svg')}
                      className="favorite-sport"
                    />
                  </div>
                ))}
              </div>

              <Button
                black
                fullwidth
                type="submit"
                disabled={isSubmitting || !(isValid && dirty)}
                arrow
                className="mt40"
              >
                {t('completeProfile.continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </SetFavoriteSportWrapper>
  );
};

export default setFavoriteSport;
