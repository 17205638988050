import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { get, remove } from '../../common/http';

const isStepCompleted = (values) => (!values || (Object.values(values).every(x => !!x)));

// Slice
const slice = createSlice({
  name: 'session',
  initialState: {
    accountInfo: null,
    friends: null,
    isLoggedIn: false,
    profileCompletion: [],
    currentStep: null,
    isProfileCompleted: true,
    currentWeather: null,
    leaderboard: {},
    isLoading: false,
    accountStatus: {},
    error: false,
    ip: null,
    showRaiseHandModal: false,
    masterSport: localStorage.getItem('masterSport') || 'tennis',
  },
  reducers: {
    setMasterSport: (state, action) => {
      state.masterSport = action.payload;
    },
    startLoading: state => {
      state.isLoading = true;
    },
    setLeaderboard: (state, action) => {
      state.leaderboard = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setBanned: (state, action) => {
      state.accountStatus = action.payload;
    },
    setIp: (state, action) => {
      state.ip = action.payload;
    },
    getAccountInfoSuccess: (state, action) => {
      state.accountInfo = {
        ...state.accountInfo,
        ...action.payload,
      };
      state.isLoading = false;
    },
    getFriendsSuccess: (state, action) => {
      state.friends = action.payload;
    },
    getAccountIsLoggedInSuccess: (state, action) => {
      state.isLoggedIn = action.payload;
      state.isLoading = false;
    },
    updateAccountInfoStateSuccess: (state, action) => {
      state.accountInfo = {
        ...state.accountInfo,
        ...action.payload,
      };
      state.isLoading = false;
    },
    getAccountProfileCompletionSuccess: (state, action) => {
      if (action.payload) {
        const payload = {
          ...state.accountInfo,
          ...action.payload,
        };
        state.profileCompletion = [
          {
            step: 'profileDetails',
            path: '/my-account/profile-details',
            completed: isStepCompleted({
              birthDate: payload?.birthDate,
              gender: payload?.gender,
              location: payload?.location,
              dominantHand: payload?.dominantHand,
            }),
          },
          /*
          {
            step: 'profileVisibility',
            path: '/my-account/profile-visibility',
            completed: isStepCompleted({
              profileVisibility: payload?.profileVisibility,
            }),
          },
           */
          {
            step: 'favoriteSport',
            path: '/my-account/favorite-sport',
            completed: isStepCompleted({
              favoriteSport: payload?.favoriteSport,
            }),
          },
          {
            step: 'gameLevel',
            path: '/my-account/game-level',
            completed: isStepCompleted({
              'gameLevel.singles': payload?.gameLevel?.singles,
              'gameLevel.doubles': payload?.gameLevel?.doubles,
            }),
          },
          /*
          {
            step: 'profilePicture',
            path: '/my-account/profile-picture',
            completed: isStepCompleted({
              profilePicture: payload?.profilePicture,
            }),
          },
          {
            step: 'availability',
            path: '/my-account/availability',
            completed: isStepCompleted({
              availability: action.payload?.availability,
            }),
          },
           */
        ];
        const [currentStep] = state.profileCompletion.filter(step => !step.completed);
        state.currentStep = currentStep;
        state.isProfileCompleted = !currentStep;
        state.isLoading = false;
      }
    },
    updateProfileCompletionSuccess: (state) => {
      state.isProfileCompleted = true;
    },
    setCurrentWeatherSuccess: (state, action) => {
      state.currentWeather = action.payload;
    },
    setShowRaiseHandModal: (state, action) => {
      state.showRaiseHandModal = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  setMasterSport,
  startLoading,
  hasError,
  getAccountInfoSuccess,
  getFriendsSuccess,
  getAccountIsLoggedInSuccess,
  updateAccountInfoStateSuccess,
  getAccountProfileCompletionSuccess,
  updateProfileCompletionSuccess,
  setCurrentWeatherSuccess,
  setLeaderboard,
  setShowRaiseHandModal,
} = slice.actions;

// Actions
export const getAccountInfo = (params = {}) => async (dispatch) => {
  let accountInfo;
  dispatch(startLoading());
  try {
    const { masterSport } = slice.getInitialState();
    if (!params.masterSport) {
      Object.assign(params, { masterSport });
    }
    accountInfo = await get(`/accounts/auth`, { params });
  } catch (error) {
    hasError(error);
    accountInfo = { error };
  }
  const { data: { data: payload } = {} } = accountInfo || {};
  dispatch(getAccountInfoSuccess(payload));
  dispatch(getAccountIsLoggedInSuccess(!isEmpty(payload)));
  dispatch(getAccountProfileCompletionSuccess(payload));
  return accountInfo;
};

export const getFriendsInfo = () => async (dispatch) => {
  let friends;
  dispatch(startLoading());
  try {
    friends = await get('/friends');
  } catch (error) {
    hasError(error);
    friends = { error };
  }
  const { data: { data: payloadFriend } = {} } = friends || {};
  dispatch(getFriendsSuccess(payloadFriend));
  return friends;
};

export const { setBanned, setIp } = slice.actions;

export const getLeaderboard = () => async (dispatch) => {
  const { data: { data } } = await get('/leaderboards/last-run');
  dispatch(setLeaderboard(data));
};

export const mailChimpSync = () => async (dispatch) => {
  await get('/accounts/mailchimp-sync');
  dispatch(getAccountInfo());
};

export const updateAccountInfoState = (payload) => async (dispatch) => {
  dispatch(startLoading());
  dispatch(updateAccountInfoStateSuccess(payload));
  dispatch(getAccountProfileCompletionSuccess(payload));
};

export const updateProfileCompletion = (payload) => async (dispatch) => {
  dispatch(updateProfileCompletionSuccess(payload));
};

export const setCurrentWeather = (payload) => async (dispatch) => {
  dispatch(setCurrentWeatherSuccess(payload));
};

export const showRaiseHandModal = () => async (dispatch) => {
  dispatch(setShowRaiseHandModal(true));
};

export const hideRaiseHandModal = () => async (dispatch) => {
  dispatch(setShowRaiseHandModal(false));
};

export const logout = (redirectUrl = '/') => async (dispatch) => {
  dispatch(startLoading());
  await remove('/accounts/auth');
  dispatch(getAccountInfoSuccess(null));
  dispatch(getAccountIsLoggedInSuccess(false));
  localStorage.clear();
  return document.location.href = redirectUrl;
};
