import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import {
  PageContainer, TabContent, ViewMore, ViewMoreArrow,
} from '../styles';

import mixpanel from '../../../mixpanel';

import Match from './components/Match';
import Header from '../components/Header';
import { fetchFriendlyMatchesScores } from '../reducers';
import { NoDataContainer } from '../StartMatch/components/WantToPlay/styles';
import LoadImage from '../../../components/common/LoadImage';
import { Button, H3 } from '../../../components';
import { P } from '../../../components/Collection';
import LinkWrapper from '../../../components/Link';
import ToggleVisible from '../../../components/common/ToggleVisible';
import ReportMatch from '../components/Modals/ReportMatch';

const Scores = ({ name }) => {
  const dispatch = useDispatch();
  const {
    data: scores,
    status,
    sportType,
  } = useSelector(state => state.friendlyMatches?.friendlyMatches?.scores);
  const { masterSport } = useSelector(state => state?.session);
  const [page, setPage] = useState(1);
  const { isComponentVisible: isModalVisible, setIsComponentVisible: setIsModalVisible } = ToggleVisible(false);

  useEffect(() => {
    if (
      (status === 'idle')
      || (status === 'succeeded' && scores?.page && scores?.page !== page)
      || (sportType && sportType !== masterSport && status === 'succeeded')
    ) {
      if (sportType !== masterSport && page > 1) {
        setPage(1);
      } else {
        dispatch(fetchFriendlyMatchesScores({ page, limit: 15 }));
      }
    }
  }, [page, status, sportType, masterSport]);

  useEffect(() => {
    mixpanel.track('Visited Scores tab');
  }, []);

  const loadMoreData = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <PageContainer>
      <Header page={name} masterSport={masterSport} />
      {scores?.results && scores?.results.length > 0 && (
        <TabContent>
          {scores?.results.map((match, key) => (
            <Match
              key={key}
              match={match}
              isScore
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />
          ))}
        </TabContent>
      )}

      {scores?.results && !scores?.results.length && status === 'succeeded' && (
        <NoDataContainer className="text-center">
          <img src={LoadImage('friendly-matches/black-palette.svg')} alt="" width={60} height={60} />
          <H3><Trans ns="friendlyMatches" i18nKey="noScores">No Scores</Trans></H3>
          <P className="mb30">
            <Trans ns="friendlyMatches" i18nKey="noScoresSubtitle">
              You have no played friendly matches with scores validated yet. Please make sure scores
              were submitted and validated for all your played matches so far, or invite your
              friends or other players and start a new match now!
            </Trans>
          </P>
          <Button wide>
            <LinkWrapper
              to="/friendly-matches"
              onClick={() => mixpanel.track('Click START MATCH placeholder from Scores')}
            >
              <Trans ns="friendlyMatches" i18nKey="startMatch">Start Match</Trans>
            </LinkWrapper>
          </Button>
        </NoDataContainer>
      )}

      {scores?.totalPages > page && (
        <ViewMore onClick={loadMoreData}>
          <P bold>
            <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
          </P>
          <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View Inactive Requests" />
        </ViewMore>
      )}

      <ReportMatch isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </PageContainer>
  );
};

export default Scores;
